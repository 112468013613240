.view-element {
  /* 同级元素重叠，需要穿透事件 */
  pointer-events: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

#selected-box {
  cursor: pointer;
}
