@font-face {
  font-family: "iconfont"; /* Project id 3239261 */
  src: url("iconfont.woff2?t=1646981020932") format("woff2"),
    url("iconfont.woff?t=1646981020932") format("woff"),
    url("iconfont.ttf?t=1646981020932") format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yidong-03-01:before {
  content: "\e600";
}

.icon-icon-10:before {
  content: "\e601";
}

.icon-icon-08:before {
  content: "\e602";
}

.icon-icon-04:before {
  content: "\e603";
}

.icon-icon-06:before {
  content: "\e604";
}

.icon-icon-09:before {
  content: "\e605";
}

.icon-icon-05:before {
  content: "\e606";
}

.icon-icon-07:before {
  content: "\e607";
}
