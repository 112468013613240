html {
  /* 16px * 62.5% = 10px */
  /* 1rem === 10px */
  font-size: 62.5%;
}

html body #root .App {
  /* 撑满整个视口 */
  min-height: 100vh;
}
