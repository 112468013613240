.data-item {
  display: flex;
  padding: 18px 22px;
  border: 1px solid #eee;
  list-style: none;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
}

.data-item:hover {
  /* background-color: #85c1db; */
  background-color: #ebf6fa;
  cursor: pointer;
}

.data-item h4 {
  padding: 0;
  margin: 0;
  font-size: 18px;
}
