.schema-item {
  cursor: pointer;
  display: flex;
  width: 287px;
  /* height: 90px; */
  border: 1px solid #eee;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.clone-schema-item {
  cursor: pointer;
  display: flex;
  width: 330px;
  height: 90px;
  border: 1px solid #eee;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: 18px;
  background-color: #e9f6fe;
}

.schema-list {
  position: absolute;
  padding-left: 0px;
  font-size: 18px;
}
